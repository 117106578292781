<template>
	<!-- 解决方案-收款明细-->
	<div>
		<div class="container">
			<GModule style="margin-bottom: 10px;">
				<ul class="money">
					<li>
						<p>收款金额</p>
						<p>{{addData.total_pay_amount}}</p>
					</li>
					<li>
						<p>提成金额</p>
						<p>{{addData.total_solution_money}}</p>
					</li>
					<li>
						<p>上月收款</p>
						<p>{{addData.lm}}</p>
					</li>
					<li>
						<p>本月收款</p>
						<p>{{addData.m}}</p>
					</li>
					<li>
						<p>本季度收款</p>
						<p>{{addData.q}}</p>
					</li>
					<li>
						<p>本年收款</p>
						<p>{{addData.y}}</p>
					</li>
				</ul>
				<div class="condition-box">
					<div style="display: flex;">
						<div class="state-box">
							<p>项目</p>
							<a-dropdown>
							    <template #overlay>
							        <a-menu selectable @click="handleMenuClick">
										<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.name}}</a-menu-item>
							        </a-menu>
							    </template>
							    <a-button v-if="stateArr.length > 0">
									{{stateArr[stateIndex].name}}
							        <DownOutlined />
							    </a-button>
							</a-dropdown>
						</div>
						<div class="state-box">
							<p>状态</p>
							<a-dropdown>
							    <template #overlay>
							        <a-menu selectable @click="handleStatus">
										<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem}}</a-menu-item>
							        </a-menu>
							    </template>
							    <a-button>
									{{nstatusIndex == 'all' ? '全部' : nstatusArr[nstatusIndex]}}
							        <DownOutlined />
							    </a-button>
							</a-dropdown>
						</div>
						<div class="state-box">
							<p style="width: 60px;">时间</p>
							<a-range-picker
							    style="width: 400px"
							    :ranges="ranges"
							    show-time
							    format="YYYY-MM-DD HH:mm:ss"
								@change="change"/>
						</div>
					</div>
					<a-button class="btn clo3" @click="search()">查询</a-button>
				</div>
				<a-table
					class="ttable" 
					sticky 
					:columns="columnsHe" 
					:data-source="data" 
					:scroll="{ x: 500}" 
					bordered
					:pagination="false">
				  <template #bodyCell="{ column ,record}">
					  <template v-if="column.title === '收款凭证'">
					  	<div class="image-w-h" v-if="record.images">
					  		<a-image :preview="false" :src="record.images" @click="openSwiper(record.images)"/>
					  	</div>
					  </template>
				  </template>
				</a-table>
				<div class="documentFt" style="margin-top: 10px;">
				  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
				</div>
			</GModule>		
		</div>
		
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	export default{
		data(){
			return{
				stateArr:[
					{id:0,name:'全部'}
				] ,
				stateIndex:0,
				nstatusArr:{},// 状态
				nstatusIndex:'all',
				columnsHe: [{
				    title: '序号',
				    width: 50,
				    dataIndex: 'id',
				    fixed: 'left'
				  }, {
				    title: '项目名称',
				    width: 100,
				    dataIndex: 'project_name'
				  }, {
				    title: '合同编号',
				    width: 120,
				    dataIndex: 'contract_no'
				  }, {
				    title: '收款期数',
				    width: 120,
				    dataIndex: 'periods'
				  }, {
				    title: '收款金额',
				    width: 120,
				    dataIndex: 'pay_amount'
				  }, {
				    title: '收款时间',
				    width: 120,
				    dataIndex: 'pay_time'
				  }, {
				    title: '收款凭证',
				    width: 120,
				    dataIndex: 'images'
				  }, {
				    title: '备注',
				    width: 120,
				    dataIndex: 'remark'
				  }, {
				    title: '利润率（%）',
				    width: 120,
				    dataIndex: 'gpm_ratio'
				  }, {
				    title: '提成比例（%）',
				    width: 120,
				    dataIndex: 'solution_ratio'
				  }, {
				    title: '提成金额',
				    width: 120,
				    dataIndex: 'solution_money'
				  }, {
				    title: '状态',
				    width: 120,
				    dataIndex: 'settlement_status_text',
					fixed: 'right'
				  }],
				data: [],
				limit:10,
				page:1,
				createtime:'' ,// 创建时间
				ranges: {
					'今天': [dayjs().startOf('day'), dayjs().endOf('day')],
					'本周': [dayjs().startOf('week'), dayjs().endOf('week')],
					'本月': [dayjs().startOf('month'), dayjs().endOf('month')],
					'一个月前': [dayjs().subtract(1, 'month'),dayjs().endOf('day')],
				},
				total:0 ,// 总条数
				addData:{} // 新增数
			}
		},
		async created() {
			// 获取项目列表
			let res = await this.$utils.api.get({
				url:'/project/my_selectpage',
				result:1
			})
			if(res.code == 1){
				this.stateArr = this.stateArr.concat(res.data.list) 
			}
			
			// 获取状态列表
			let ress = await this.$utils.api.get({
				url:'/contract/setting',
				result:1
			})
			if(ress.code == 1){
				this.nstatusIndex = 'wait' // 默认待结算
				let a = {all:"全部"}
				this.nstatusArr = {...a,...ress.data.settlementStatusList}
			}
			// 初始化列表
			this.getList()
		},
		methods:{
			handleMenuClick(e){
				this.stateIndex = e.key
			},
			handleStatus(e){
				this.nstatusIndex = e.key
			},
			change(date,value){
				// 选择创建时间
				if(value[0] == '' || value[1] == ''){
					// 创建时间要同时满足开始时间和结束时间才可以查询
					this.createtime = ''
					return
				}
				let val = value.join(' - ') // 拼接日期
				this.createtime = val
			},
			pageChange(page,pageSize){
				// 切换页码时查询列表
				this.page = page
				this.getList()
			},
			search(){
				// 根据条件查询列表
				this.page = 1 //每次点击查询按钮都要重置页码为1
				this.getList()
			},
			async getList(){
				// 请求列表
				let info = {
					limit:this.limit,
					page:this.page,
					project_id:this.stateArr[this.stateIndex].id,
					settlement_status: this.nstatusIndex == 'all' ? '' : this.nstatusIndex,
					pay_time:this.createtime
				}
				let res = await this.$utils.api.post({
					url:'/contract/collection_index',
					data:info,
					result:1
				})
				if(res.code == 1){
					this.data = res.data.rows
					this.total = res.data.total
					this.addData = res.data.extend
					this.addData.total_pay_amount = parseFloat(this.addData.total_pay_amount).toFixed(2);
					this.addData.total_solution_money = parseFloat(this.addData.total_solution_money).toFixed(2);
					this.addData.lm = parseFloat(this.addData.lm).toFixed(2);
					this.addData.m = parseFloat(this.addData.m).toFixed(2);
					this.addData.q = parseFloat(this.addData.q).toFixed(2);
					this.addData.y = parseFloat(this.addData.y).toFixed(2);
				}
			},
			openSwiper(img){
				// 弹出图片
				let imgs = []
				imgs.push(img)
				this.$store.commit('setSwiperArr',imgs)
				// 弹出图片列表
				this.$store.commit('setSwiper',1)
			},
		}
	}
</script>

<style scoped lang="scss">
	.container{
		margin: 20px;
		overflow: hidden;
		.state-box{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
		}
		.time-box{
			display: flex;
			align-items: center;
			p{
				&:nth-child(1){
					margin-right: 15px;
				}
				&:nth-child(2){
					width: 80px;
					font-size: 12px;
					color: #999999;
					border: 1px solid #ddd;
					border-radius: 5px;
					padding: 5px 10px;
				}
			}
		}
		.money{
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			background-color: #f5f5f5;
			margin-bottom: 15px;
			li{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex: 1;
				padding: 20px 0;
				p{
					margin: 0;
					padding: 0;
					&:nth-child(1){
						margin-bottom: 10px;
					}
				}
			}
		}
		.condition-box{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 5px 0 10px 0;
			.state-box{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 5px;
				p{
					margin: 0 10px 0 20px;
				}
			}
			.btn{
				margin-left: 20px;
				margin-bottom: 5px;
			}
		}
		.caozuo{
			display: flex;
			justify-content: space-around;
			align-items: center;
			p{
				margin: 0;
				font-size: 12px;
				color: #407cff;
			}
		}
	}
</style>